<template>
  <b-card title=" Other Courses">
    <b-tabs>
     <b-tab :active="currentRouteName=='add-other-course'" @click="$router.push('/other-course/add-other-course')">
        <template #title>
          <feather-icon icon="FolderIcon" />
          <!-- <a  href="/add-other-course">New Dedicated Course </a> -->
          <span>New Dedicated Course</span>
        </template>

  
      </b-tab>
      <b-tab  :active="currentRouteName=='deticated-courses'" @click="$router.push('/other-course/deticated-courses')" >
        <template #title>
          <feather-icon icon="HomeIcon" />
          <!-- <a  href="/deticated-courses"> Dedicated Course</a> -->
          <span>Dedicated Course</span>
        </template>
      

        <!-- <DeticatedCourses ref="allData"></DeticatedCourses> -->
      </b-tab>
     
       <b-tab :active="currentRouteName=='schedual-deticatedd-courses'" @click="$router.push('/other-course/schedual-deticatedd-courses')" >
        <template #title>
          <feather-icon icon="PlusIcon" />
          <span>Schedule  Course</span>
          <!-- <a  href="/schedual-deticatedd-courses">Schedule  Course</a> -->
        </template>
      

<!-- <SchedualDeticatedCourses></SchedualDeticatedCourses> -->
      </b-tab>
      <b-tab :active="currentRouteName=='Deticated-courses-content'" @click="$router.push('/other-course/content')" >
        <template #title>
          <feather-icon icon="PlusIcon" />
          <span>  Content</span>
          <!-- <a  href="/content"> Content</a> -->
        </template>
      
<!-- <DeticatedCourseContent></DeticatedCourseContent> -->
      </b-tab>
       <b-tab :active="currentRouteName=='seo-detcated-course'" @click="$router.push('/other-course/seo-detcated-course')" >
        <template #title>
          <feather-icon icon="PlusIcon" />
          <span>SEO</span>
          <!-- <a  href="/seo-detcated-course"> SEO</a> -->
        </template>
      
<!-- <SeoDetcatedCourse></SeoDetcatedCourse> -->
      </b-tab>
      <b-tab :active="currentRouteName=='deticated-schedual-courses-list'" @click="$router.push('/other-course/deticated-schedual-courses-list')" >
        <template #title>
          <feather-icon icon="PlusIcon" />
          <span>All Scheduled</span>
          <!-- <a  href="/deticated-schedual-courses-list"> All Scheduled</a> -->
        </template>
      
<!-- <SeoDetcatedCourse></SeoDetcatedCourse> -->
      </b-tab>
     
    </b-tabs>
    <router-view v-slot="{ Component, route }">
      <AddCourse v-if="route.name === 'index-other-course ' ||route.name === 'add-other-course' "  />
      <router-view  v-else>
          <component :is="Component" />
        </router-view>
  </router-view>
   
  </b-card>
</template>

<script>

import { BTabs, BTab, BCardText,BCard } from 'bootstrap-vue'

import AddCourse from '../othercourses/AddCourse.vue';
import DeticatedCourses from './DeticatedCourses.vue';
import SchedualDeticatedCourses from './SchedualDeticatedCourses.vue';
import DeticatedCourseContent from './DeticatedCourseContent.vue';
import SeoDetcatedCourse from './SeoDetcatedCourse.vue';
import { ref } from "@vue/composition-api";


export default {
  components: {
    BCard,
    BCardText,
    BTabs,
    BTab,
  
    AddCourse,
    DeticatedCourses,
    SchedualDeticatedCourses,
    DeticatedCourseContent,
    SeoDetcatedCourse
},
  
   setup() {
    const allData=ref()
  
    const getData=()=>{
    
      // allData.value.getothercoursess()
           

    }
   return{
    getData,
    allData,
   

   }
   
   },
   computed: {
    currentRouteName() {
        return this.$route.name;
    }}}
</script>
